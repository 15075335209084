<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('learn.configure')" class="settings">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="4" xl="4" class="pt-0 pb-0 text-left">
              <span>{{$t('common.Auto_moment')}} {{loadingData === false ? ': ' + event.description : ''}}</span>
            </CCol>
            <CCol v-if="loadingData === false" cols="8" xl="8" class="pt-0 pb-0 text-xl-right">
              <div class="mt-1 mt-xl-0 d-flex justify-content-lg-end">
                <CButton v-if="checkPermission('core.automoment.editconfig')" @click="openSidebarRight('product_auto_moment_details', { platform_event_type_id_external: event.platform_event_type_id_external, product_tag:  'learn'})" color="primary" class="admin btn_small">                                
                  <span><i class="fa-solid fa-sliders mr-1"></i>{{$t('communications.Edit_moment_config')}}</span>
                </CButton>
                <!-- <CButton v-if="checkPermission('core.automoment.defaultconfig')" @click="setEventDefaults(event.tag)" color="primary" class="admin btn_small">                                
                  <span><i class="fa-solid fa-gear mr-1"></i>{{$t('communications.Set_defaults')}}</span>
                </CButton> -->
                <CButton v-if="checkPermission('core.emailtemplates') &&
                               event.is_active === true &&
                               event.email_is_active === true && 
                               event.email_is_available === true && 
                               templates.length > 0" 
                         @click="selectTemplateView = true" 
                         color="secondary" 
                         class="btn_small">
                  <span><i class="fa-solid fa-envelope mr-1"></i>{{$t('common.Load_email_template')}}</span>
                </CButton>
                <CButton v-if="checkPermission('core.emailtemplates') &&
                               event.is_active === true && 
                               event.email_is_active === true &&
                               event.email_is_available === true &&
                               event.email_content.json"
                         @click="openSidebarRight('save_email_template', { template_type: 'email', template_source_id_external:  event.platform_event_type_id_external, product_tag: 'learn' })" 
                         color="secondary"
                         class="btn_small">
                  <span><i class="fa-solid fa-envelope-circle-check mr-2"></i>{{$t('common.Save_as_email_template')}}</span>
                </CButton>
                <CButton v-if="event.is_active === true && (event.email_is_active === false || (event.email_is_active === true && emailEditorReady === true))" @click="updateEventContent(event.platform_event_type_id_external, event);" color="primary" class="m-0 btn_small">
                  <span><i class="fas fa-check mr-1"/>{{$t('communications.Update_auto_moment')}}</span>
                </CButton>                
              </div>             
            </CCol>            
          </CRow>
        </CCardHeader>
        <CCardBody class="event" v-bind:class="{'pb-0' : event.is_required}">
          <CRow v-if="loadingData === true">
            <CCol cols="12" lg="12">
              <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
            </CCol>
          </CRow>
          <CRow v-if="loadingData === false">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <CRow v-if="event.is_active === true" class="p-0">
                <CCol cols="12" lg="12" class="pt-0 pb-0">
  
                  <transition name="slide-big">
                    <CRow v-if="selectTemplateView">
                      <CCol cols="12" lg="12" class="pt-0 pb-0">
                        <span>{{$t('common.Pick_mail_template')}}</span>
                      </CCol>
                      <CCol cols="3" lg="3">
                        <navigationCard @click.native="setEmailTemplate(event.tag, event.email_content.json)" path="" :cardTitle="$t('common.Current_template')" :cardDescription="$t('common.Keep_using_current_template')" :cardIcon="null" class="h-100"/>
                      </CCol>                                     
                      <CCol v-for="template in templates" v-bind:key="template.platform_event_email_template_id_external" cols="3" lg="3">
                        <navigationCard @click.native="setEmailTemplate(event.tag, template.json)" path="" :cardTitle="template.name" :cardDescription="template.description" :cardIcon="null" class="h-100"/>
                      </CCol>
                    </CRow>
                  </transition>                               

                  <CRow>
                    <CCol v-if="event.points_are_available === true" :cols="event.send_on_date_is_available ? 8 : 12" :xl="event.send_on_date_is_available ? 8 : 12" :lg="event.send_on_date_is_available ? 8 : 12" md="12" sm="12" class="pt-0">
                      <b-collapse class="panel show" :open="true">
                        <div slot="trigger" slot-scope="props" class="panel-heading" role="button">
                          <strong>{{$t('communications.points')}}</strong>
                          <a>{{props.open ? $t('close') : $t('open')}}</a>
                        </div>
                        <div class="panel-block p-0">
                          <CRow class="w-100 m-0">
                            <CCol v-if="event.tag !== 'birthday'" cols="7" xl="7" :lg="event.send_on_date_is_available ? 12 : 7" md="12" sm="12" class="h-100">
                              <CInput @input="countdown()" type="text" maxlength="50" :label="$t('communications.Points_title')" class="mt-0 mb-2" v-model="event.points_title"></CInput>
                              <span class="countdown" v-if="event.remaining_post_title_length > 0">{{event.remaining_post_title_length}} {{$t('common.characters_remaining')}}</span>
                              <CTextarea @input="countdown()" rows="3" maxlength="200" :label="$t('communications.Points_description')" class="mt-0 mb-2" v-model="event.points_description"/>
                              <span class="countdown" v-if="event.remaining_post_description_length > 0">{{event.remaining_post_description_length}} {{$t('common.characters_remaining')}}</span>
                            </CCol>                            
                            <CCol>
                              <MomentPoints :platformPermissions="platformPermissions" parent="auto-moment" :points="event.points" :boxed="false" :editable="true"/>
                            </CCol>
                          </CRow>
                        </div>
                      </b-collapse>
                    </CCol>
                    <CCol v-if="event.send_on_date_is_available === true" class="pt-0" cols="4">
                      <b-collapse class="panel show" :open="true">
                        <div slot="trigger" slot-scope="props" class="panel-heading" role="button">
                          <strong>{{$t('communications.Send_on_date')}}</strong>
                          <a>{{props.open ? $t('close') : $t('open')}}</a>
                        </div>
                        <div class="panel-block p-0">
                          <CRow v-if="event.send_on_date_is_required === false" class="w-100 m-0">
                            <CCol cols="12" lg="12" class="pb-0">
                              <b-switch class="mb-0 mt-1 mt-xl-0" v-model="event.send_on_date_is_active" size="is-small" @input="setEventStatus(event.platform_event_type_id_external, event)">{{$t('communications.Send_on_specific_date')}}</b-switch>     
                            </CCol>
                          </CRow>
                          <CRow v-if="event.send_on_date_is_active === true" class="w-100 m-0">
                            <CCol cols="12" xl="12">
                              <label>{{$t('communications.Send_communication_on_date')}}</label>
                              <b-datetimepicker v-model="event.send_on_date" :placeholder="$t('common.click_to_select')" editable :datepicker="datepickerOptions" :timepicker="timepickerOptions"></b-datetimepicker>
                            </CCol>
                          </CRow>
                        </div>
                      </b-collapse>
                    </CCol>
                  </CRow>                          

                  <CRow v-if="event.email_is_available === true && event.email_is_active === true" class="p-0">
                    <CCol cols="12" lg="12" class="pt-0">
                      <b-collapse class="panel show" :open="true">
                        <div slot="trigger" slot-scope="props" class="panel-heading" role="button">
                          <strong>{{$t('communications.email')}}</strong>
                          <a>{{props.open ? $t('close') : $t('open')}}</a>
                        </div>
                        <div class="panel-block p-0">
                          <CRow class="w-100 m-0">
                            <CCol cols="12" lg="12" class="pb-0">
                              <CInput type="text" :label="$t('common.Subject')" class="mb-0" v-model="event.email_content.subject" required was-validated/>
                            </CCol>
                          </CRow>
                          <CRow class="w-100 m-0">
                            <CCol cols="12" lg="12" class="pb-0">
                              <label class="mt-0 mb-2">{{$t('communications.email_content')}}</label>
                              <div v-if="event.email_content_tags" class="mb-1">
                                <span>{{ $t('communications.available_tags') }} {{event.email_content_tags}}</span>
                              </div>
                            </CCol>
                          </CRow>
                          <CRow v-if="!emailEditorInstructionsRead" class="w-100 m-0">
                            <CCol cols="12" lg="12">
                              <EmailEditorInstructions/>
                            </CCol>
                          </CRow>
                          <CRow class="w-100 m-0">
                            <CCol cols="12" lg="12">
                              <EmailEditor :ref="'emailEditor_' + event.tag"
                                            :appearance="emailEditorConfig.appearance"
                                            :min-height="emailEditorConfig.minHeight"
                                            :project-id="emailEditorConfig.projectId"
                                            :locale="emailEditorConfig.locale"
                                            :tools="emailEditorConfig.tools"
                                            :options="emailEditorConfig.options"
                                            v-on:ready="editorReady(event.email_is_available, event.email_is_active, event.tag)">
                              </EmailEditor>
                            </CCol>
                          </CRow>               
                        </div>
                      </b-collapse>
                    </CCol>
                  </CRow>

                  <CRow v-if="modules.social_wall == 1 && event.sw_post_is_available === true">
                    <CCol cols="12" lg="12" class="pt-0">
                      <b-collapse class="panel show" :open="true">
                        <div slot="trigger" slot-scope="props" class="panel-heading" role="button">
                          <strong>{{$t('communications.sw')}}</strong>
                          <a>{{props.open ? $t('close') : $t('open')}}</a>
                        </div>
                        <div class="panel-block p-0">
                          <CRow v-if="event.sw_post_is_required === false" class="w-100 m-0">
                            <CCol cols="12" lg="12" v-bind:class="{'pb-0' : event.sw_post_is_active }">
                              <b-switch class="mb-0 mt-1 mt-xl-0" v-model="event.sw_post_is_active" size="is-small" @input="setEventStatus(event.platform_event_type_id_external, event)">{{$t('communications.post_on_sw')}}</b-switch> 
                            </CCol>
                          </CRow>                                  
                          <CRow v-if="event.sw_post_is_active === true" class="w-100 m-0">
                            <CCol :cols="event.event_has_images === true ? 6 : 12" :lg="event.event_has_images === true ? 6 : 12">
                              <div v-if="event.sw_post_content_tags" class="mb-1">
                                <span>{{ $t('communications.available_tags') }} {{event.sw_post_content_tags}}</span>
                              </div>

                              <ckeditor ref="contentEditor" @input="countdown()" :editor="editor" v-model="event.post_content.content" :config="editorConfig"></ckeditor>
                              <p class="countdown mt-1 mb-0" v-if="event.post_content.remaining_content_length > 0">{{event.post_content.remaining_content_length}} {{$t('common.characters_remaining')}}</p>
                              <p class="countdown warning mt-1 mb-0" v-if="event.post_content.remaining_content_length < 0">{{event.post_content.content.length - event.post_content.max_content_length}} {{$t('common.characters_too_much')}}</p>                            
                            </CCol>

                            <CCol v-if="event.event_has_images === true" cols="6" lg="6" class="pt-0 pb-0">
                              <CRow class="w-100 m-0">
                                <CCol cols="12" lg="12" class="event_images">
                                  <label>{{$t('communications.upload_moment_images')}}</label>
                                  <div class="event_images_preview" v-bind:class="{ 'mb-3' : event.new_event_images.length > 0 }">
                                    <div v-for="(image, imageIndex) in event.new_event_images" v-bind:key="imageIndex" class="event_image mr-2">
                                      <img :src="image.image_url">
                                      <i class="fas fa-times" @click="removeEventImage(image, imageIndex)"/>
                                    </div>
                                  </div>
                                  <div>
                                    <input id="imageUpload" multiple type="file" accept="image/*" :value="event.uploaded_event_images" @change="onEventImagesChange" hidden>
                                    <CButton color="primary" @click="pickEventImages();"><i class="fas fa-image mr-1"/>{{$t('sw.upload_pictures')}}</CButton>
                                  </div>
                                </CCol>
                                <CCol v-if="event.event_images.length > 0" cols="12" lg="12" class="pt-0 pb-0">
                                  <label>{{ $t('sw.current_pictures') }}</label>
                                  <div class="current_event_images">
                                    <div v-for="(image, index) in event.event_images" v-bind:key="image.post_image_id" class="event_image mr-2 mb-2">
                                      <img :src="apiBaseUrl + '/v1/common/cdn/file/image/core-platform-event/' + event.tag + '/' + image.file + '/' + clientToken"/>
                                      <i class="fas fa-times" @click="removeEventImage(image, index)"/>
                                    </div>
                                  </div>
                                </CCol>
                              </CRow>
                            </CCol>
                          </CRow>
                          </div>
                      </b-collapse>
                      </CCol>
                  </CRow>

                  <CRow v-if="event.push_is_available === true">
                    <CCol cols="12" lg="12" class="pt-0">
                      <b-collapse class="panel show" :open="true">
                        <div slot="trigger" slot-scope="props" class="panel-heading" role="button">
                          <strong>{{$t('communications.push')}}</strong>
                          <a>{{props.open ? $t('close') : $t('open')}}</a>
                        </div>
                        <div class="panel-block p-0">
                          <CRow v-if="event.push_is_required === false" class="w-100 m-0">
                            <CCol cols="12" lg="12" v-bind:class="{'pb-0' : event.push_is_active }">
                              <b-switch class="mb-0 mt-1 mt-xl-0" v-model="event.push_is_active" size="is-small" @input="setEventStatus(event.platform_event_type_id_external, event)">{{$t('communications.send_push')}}</b-switch>
                            </CCol>
                          </CRow>
                          <CRow v-if="event.push_is_active === true" class="w-100 m-0">
                            <CCol cols="6" lg="6">
                              <CInput @input="countdown()" rows="2" maxlength="20" :label="$t('communications.push_title')" class="mb-0" v-model="event.push_content.title"/>
                              <p class="countdown mt-1 mb-0" v-if="event.push_content.remaining_title_length > 0">{{event.push_content.remaining_title_length}} {{$t('common.characters_remaining')}}</p>
                            </CCol>
                            <CCol cols="6" lg="6">
                              <CInput @input="countdown()" rows="2" maxlength="60" :label="$t('communications.push_message')" class="mb-0" v-model="event.push_content.message"/>
                              <p class="countdown mt-1 mb-0" v-if="event.push_content.remaining_message_length > 0">{{event.push_content.remaining_message_length}} {{$t('common.characters_remaining')}}</p>
                            </CCol>                                
                          </CRow>
                        </div>
                      </b-collapse>
                    </CCol>
                  </CRow>

                  <CRow v-if="event.is_required === false">
                    <CCol class="pt-0 pb-0 text-right">
                      <CButton @click="confirmModal = true; confirmModalData = event;" color="outline-danger" class="m-0 ml-2 btn_small text_only">
                        <span><i class="fas fa-trash mr-1"/>{{$t('Delete')}}</span>
                      </CButton>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <CRow v-else class="p-0">
                <CCol cols="12" lg="12" class="pt-0 pb-0">
                  <span>{{$t('communications.Auto_moment_deactivated')}}</span>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>

      <b-modal :can-cancel="['x']" :active.sync="confirmModal" :width="960" scroll="keep">
        <CCard class="mb-0">
          <CCardHeader class="pb-0">
            {{ $t('communications.Remove_moment') }} {{confirmModalData.description}}
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <p class="m-0">{{ $t('communications.Are_you_sure_to_delete') }} {{confirmModalData.description}}?</p>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton color="primary" @click="deactivateCompanyEvent(confirmModalData.platform_event_type_id_external);"><i class="fas fa-trash mr-1"/>{{ $t('Delete') }}</CButton>
            <CButton color="secondary" @click="confirmModal = false"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
          </CCardFooter>
        </CCard>
      </b-modal>             
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import { EmailEditor } from 'vue-email-editor';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import navigationCard from '@/components/common/navigationCard';

import EmailEditorInstructions from '@/components/core/EmailEditorInstructions.vue';
import MomentPoints from '@/components/core/MomentPoints.vue';

export default {
  name: 'Moment',
  components: {
    EmailEditor,
    loadingSpinner,
    noPermission,
    navigationCard,
    EmailEditorInstructions,
    MomentPoints
  },
  watch: { 
    '$route.params.id': {
      handler() {
        this.eventTypeIdExternal = this.$route.params.id;
        // Get the post data
        this.getEventContent(this.eventTypeIdExternal);
      },
      deep: true,
      immediate: true
    }
  },  
  data() {
    return {
      modules: { social_wall: 0 },
      eventTypeIdExternal: null,
      platformPermissions: [],
      platformPermissionsLoaded: false,
      emailEditorInstructionsRead: false,
      apiBaseUrl: null,
      clientToken: null,
      event: {},
      templates: [],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ],
      },
      uploadingFiles: false,   
      loadingData: false,
      eventDataLoaded: false,
      selectTemplateView: false,
      confirmModal: false,
      confirmModalData: {},
      emailEditorConfig: {
        minHeight: '700px',
        locale: 'en',
        projectId: 50935, //'50935' = Harry HR
        tools: {
          image: { enabled: true } // Disable image tool
        },
        options: {
          mergeTags: {
            name: {
              name: "Employee Name",
              value: "{$name}"
            },
            ceo_name: {
              name: "Email Signatory",
              value: "{$email_signatory}"
            },            
            company: {
              name: "Platform Name",
              value: "{$platform_name}"
            },
            team: {
              name: "Employee Team",
              value: "{$team}"
            },
            course: {
              name: "Completed Learn Course",
              value: "{$course}"
            },
            points: {
              name: "Received Points",
              value: "{$points}"
            },
            copyright: {
              name: "Copyright",
              value: "{$copyright}"
            }                          
          },          
          specialLinks: [
            {
              name: 'Frequently used',
              specialLinks: [
                {
                  name: 'Link to Dashboard',
                  href: '{$dashboard_url}',
                  target: '_blank',
                },
                {
                  name: 'Link to Experience',
                  href: '{$app_url}',
                  target: '_blank',
                }
              ]
            }
          ]
        },
        appearance: {
          theme: 'light',
          panels: {
            tools: {
              dock: 'right',
            }
          }
        },
        // fonts: {
        //   showDefaultFonts: false,
        //   customFonts: [{
        //     label: "Quicksand",
        //     value: "'Quicksand', sans-serif",
        //     url: "https://fonts.googleapis.com/css?family=Quicksand:400,500,700"
        //   }]
        // }               
      },
      datepickerOptions: {
        yearsRange: [0, 10],
        firstDayOfWeek: 1,
        showWeekNumber: true
      },
      timepickerOptions: {
        incrementHours: 1,
        incrementMinutes: 15
      },
      emailEditorReady: false,
      newEventPoints: null
    }
  },
  methods: {
    pickEventImages() {
      document.getElementById("imageUpload").click()
    },
    removeEventImage(data, imageIndex) {
      if(data.platform_event_image_id !== null && data.platform_event_image_id !== undefined) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/core/event/learn/deleteEventImage', data)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('communications.Moment_image_removed'), type: 'is-success', duration: 2000 });
          this.event.event_images = res.data.data;
        })
        .catch(err => {
          // console.error(err);
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
      } else {
        this.event.new_event_images.splice(imageIndex, 1);
      }
    },    
    onEventImagesChange(e) {
      let files = e.target.files;
      // Loop through the files
      for(var i = 0; i < files.length; i++) {
        // Create an image URL for the file
        files[i].image_url = URL.createObjectURL(files[i]);
        // Add the file to the new_post_images array of the post modal data
        this.event.new_event_images.push(files[i]);
      }
    },
    countdown() {
      // Calculate remaining points title length
      if(this.event.points_title !== null) this.event.remaining_post_title_length = this.event.max_points_title_length - this.event.points_title.length;
      // Calculate remaining points description length
      if(this.event.points_description !== null) this.event.remaining_post_description_length = this.event.max_points_description_length - this.event.points_description.length;
      // Calculate remaining post content length
      if(this.event.post_content.content !== null) this.event.post_content.remaining_content_length = this.event.post_content.max_content_length - this.event.post_content.content.length;
      // Calculate remaining push title length
      if(this.event.push_content.title !== null) this.event.push_content.remaining_title_length = this.event.push_content.max_title_length - this.event.push_content.title.length;
      // Calculate remaining push message length
      if(this.event.push_content.message !== null) this.event.push_content.remaining_message_length = this.event.push_content.max_message_length - this.event.push_content.message.length;
    },
    setEventStatus(eventTypeIdExternal, eventData) {
      let params = {};
      params = eventData;
      (params.is_active == true) ? params.active = 1 : params.active = 0;
      (params.sw_post_is_active == true) ? params.sw_post_active = 1 : params.sw_post_active = 0;
      (params.email_is_active == true) ? params.email_active = 1 : params.email_active = 0;
      (params.push_is_active == true) ? params.push_active = 1 : params.push_active = 0;

      axios.put(process.env.VUE_APP_API_URL + '/v1/core/event/learn/' + eventTypeIdExternal + '/status', params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('communications.Auto_moment_status_updated'), type: 'is-success', duration: 2000 });

        if(this.event.email_is_available && this.event.email_is_active) {
          // Get the mailDesign of the campaign
          let mailDesign = JSON.parse(this.event.email_content.json);
          let eventTag = this.event.tag;
        
          setTimeout(function() {
            if(this.$refs['emailEditor_' + eventTag] && this.$refs['emailEditor_' + eventTag].editor) {
              this.$refs['emailEditor_' + eventTag].editor.loadDesign(mailDesign);
            }
          }.bind(this), 2000);
        }      
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    updateEventContent(eventTypeIdExternal, eventData) {
      let params = {};
      params = eventData;
      if(!params.email_content.json) params.email_content.json = null;
      if(!params.email_content.html) params.email_content.html = null;
      if(params.email_content.content === '') params.email_content.content = null;
      if(this.newEventPoints) this.event.points = parseInt(this.newEventPoints);

      if(params.email_is_active && this.$refs !== undefined && this.$refs['emailEditor_' + eventData.tag] !== undefined ) {
        this.$refs['emailEditor_' + eventData.tag].editor.saveDesign((design) => {
          params.email_content.json = JSON.stringify(design);
        });

        this.$refs['emailEditor_' + eventData.tag].editor.exportHtml((data) => {
          params.email_content.html = data.html;
        })
      }
      
      if(params.send_on_date_is_active === true && params.send_on_date !== null) {
        params.event_send_on_date = this.$luxon(params.send_on_date.toISOString(), "yyyy-MM-dd HH:mm:ss");
      }

      // Set 500ms timeout to make sure that the JSON and HTML data is added to the emailParams
      setTimeout(function() {
        axios.put(process.env.VUE_APP_API_URL + '/v1/core/event/learn/' + eventTypeIdExternal, params)
        .then(res => {
          // Handle the image upload if the event contains new images
          if(params.event_has_images && params.new_event_images.length > 0) {
            // Open the loader
            this.uploadingFiles = true;
            // Get the event tag
            let eventTag = params.tag;              
            // Create new formdata              
            const formData = new FormData();
            // Loop through the images and append the images to the formdata
            for(var i = 0; i < params.new_event_images.length; i++) {
              formData.append("image_" + i, params.new_event_images[i]);
            }
            // Upload the images
            axios.post('v1/common/upload/images/event/' + eventTag, formData, {                
              headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then(res => {
              this.uploadingFiles = false;
              this.$buefy.toast.open({ message: this.$t('communications.Auto_moment_updated'), type: 'is-success', duration: 2000 });
              this.getEventContent();               
            })
            .catch(err => {
              console.error(err); 
            });
          } else {
            this.$buefy.toast.open({ message: this.$t('communications.Auto_moment_updated'), type: 'is-success', duration: 2000 });
            // Update the event content
            this.getEventContent(eventTypeIdExternal);           
          }
        })
        .catch(err => {
          console.error(err);
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
      }.bind(this), 500);      
    },
    getEventContent(eventTypeIdExternal) {
      // Start the loader
      if(!this.eventDataLoaded) this.loadingData = true;
      // Get the event data
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/event/learn/' + eventTypeIdExternal)
      .then(res => {
        this.event = res.data.data;
        // Set the max lengths
        this.event.post_content.max_content_length = 5000;
        this.event.push_content.max_title_length = 20;  
        this.event.push_content.max_message_length = 60;
        this.event.max_points_title_length = 50;
        this.event.max_points_description_length = 200;        
        // Calculate the remaining post content length
        (this.event.post_content.content !== null) ? this.event.post_content.remaining_content_length = 5000 - this.event.post_content.content.length : this.event.post_content.remaining_content_length = 5000;
        // Calculate the remaining push title length
        (this.event.push_content.title !== null) ? this.event.push_content.remaining_title_length = 20 - this.event.push_content.title.length : this.event.push_content.remaining_title_length = 20;          
        // Calculate the remaining push message length
        (this.event.push_content.message !== null) ? this.event.push_content.remaining_message_length = 60 - this.event.push_content.message.length : this.event.push_content.remaining_message_length = 60;
        // Calculate remaining points title length
        (this.event.points_title !== null) ? this.event.remaining_post_title_length = 50 - this.event.points_title.length : this.event.remaining_post_title_length = 50;
        // Calculate remaining points description length
        (this.event.points_description !== null) ? this.event.remaining_post_description_length = 200 - this.event.points_description.length : this.event.remaining_post_description_length = 200; 
        // Set the send_on_date value if necessary
        if(this.event.send_on_date_is_available && this.event.send_on_date_is_active) {
          this.event.send_on_date = new Date(this.event.send_on_date);
        }
        // Stop the loader
        this.loadingData = false;
        // Update the eventDataLoaded value
        this.eventDataLoaded = true;
                
        if(this.event.email_is_available && this.event.email_is_active) {                              
          let eventTag = this.event.tag;
          // Check if the email content contains a valid JSON
          if(this.event.email_content.json) {
            // If so, set he mailDesign in the editor
            let mailDesign = JSON.parse(this.event.email_content.json);
            
            setTimeout(function() {
              if(this.$refs['emailEditor_' + eventTag] && this.$refs['emailEditor_' + eventTag].editor) {
                this.$refs['emailEditor_' + eventTag].editor.loadDesign(mailDesign);
              }
            }.bind(this), 2000);
          } else {
            // Set the default email template
            this.setDefaultEmailTemplate(eventTag); 
          }        
        }              
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    editorReady(emailAvailable, emailActive, tag) {
      this.emailEditorReady = true;
      
      if(emailAvailable && emailActive) {
        this.$refs['emailEditor_' + tag].editor.setBodyValues({
          backgroundColor: "#F6F6F6",
          textColor: '#4A4A4A',
          contentAlignment: 'center',
          links: {
            color: '#4A4A4A',
            underline: true
          }
        });
      }
    },
    getEmailTemplates() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/email/templates/list')
      .then(res => {      
        this.templates = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });              
    },
    setEmailTemplate(eventTag, templateData) {
      // Update selectTemplateView value to close the view
      this.selectTemplateView = false;
        
      let mailDesign = JSON.parse(templateData);

      setTimeout(function(){          
        this.$refs['emailEditor_' + eventTag].editor.loadDesign(mailDesign);
      }.bind(this), 1000);
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
        // Get available email templates if user has permission
        if(this.platformPermissions.includes('core.emailtemplates') === true) this.getEmailTemplates();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    deactivateCompanyEvent(eventTypeIdExternal) {
      axios.put(process.env.VUE_APP_API_URL + '/v1/core/event/learn/' + eventTypeIdExternal + '/deactivate')
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('communications.Auto_moment_deleted'), type: 'is-success', duration: 2000 });
        // Close the modal
        this.confirmModal = false;
        // Return to the previous page
        this.$router.back();
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;    
      })
      .catch(err => {
        console.error(err); 
      });
    },
    /* setEventDefaults(eventTypeTag) {
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/event/learn/' + eventTypeTag + '/defaults')
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('communications.Moment_defaults_set_or_updated'), type: 'is-success', duration: 2000 }); 
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })      
    }, */
    setDefaultEmailTemplate(eventTag) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/email/template/default')
      .then(res => {        
        let defaultTemplateData = res.data.data;
        // Check if the default template data is available
        if(defaultTemplateData) {
          let mailDesign = JSON.parse(defaultTemplateData.json);
          
          setTimeout(function() {
            if(this.$refs['emailEditor_' + eventTag] && this.$refs['emailEditor_' + eventTag].editor) {
              this.$refs['emailEditor_' + eventTag].editor.loadDesign(mailDesign);
            }
          }.bind(this), 2000);
        }
      })
      .catch(err => {
        console.error(err); 
      });        
    }  
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');

    this.checkModules();
    this.getPlatformPermissions();

    if(localStorage.getItem('emailEditorInstructionsRead') !== null) {
      // Update the emailEditorInstructionsRead value if necessary
      if(localStorage.getItem('emailEditorInstructionsRead') === 'Y') this.emailEditorInstructionsRead = true;
    }    

    this.$bus.$on('email_template_added', (e) => {
      this.getEmailTemplates();
    });
    
    this.$bus.$on('email_instructions_read', (e) => {
      this.emailEditorInstructionsRead = true
    });
    
    this.$bus.$on('event_points_updated', (e) => {
      this.newEventPoints = e.new_event_points;
    });

    this.$bus.$on('learn_moment_config_updated', (e) => {
      this.getEventContent(this.eventTypeIdExternal);
    });
  },
  beforeDestroy() {
    this.$bus.$off('email_template_added');
    this.$bus.$off('email_instructions_read');
    this.$bus.$off('event_points_updated');
    this.$bus.$off('learn_moment_config_updated');
  }
}
</script>
